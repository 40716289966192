

// Notification on or off
export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Notification constant
export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";