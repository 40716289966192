import React, { Component } from "react";
import Identicon from "identicon.js";
import TextToImage from "./TextToImage";

class Main extends Component {
 render() {
  return (
   <div className="container-fluid mt-5">
    <TextToImage name="Hello world!" x="0" y="50" componentRef={this.props.componentRef} />
    <div className="row">
     <main
      role="main"
      className="col-lg-12 ml-auto mr-auto"
      style={{ maxWidth: "500px" }}
     >
      <div className="content mr-auto ml-auto">
       <p>&nbsp;</p>
       <h2>Mint your NFT..!!</h2>
       <form
        onSubmit={(event) => {
         event.preventDefault();
         const description = this.imageDescription.value;
         this.props.uploadImage(description);
        }}
       >
        <div className="form-group mr-sm-2">
         <br></br>
         <input
          id="imageDescription"
          type="text"
          ref={(input) => {
           this.imageDescription = input;
          }}
          className="form-control"
          placeholder="NFT text..."
          required
         />
        </div>
        <button type="submit" class="btn btn-primary btn-block btn-lg">
         Mint!
        </button>
       </form>
       <br />
       <br />
       <h2>Find your token URI</h2>
       <form
        onSubmit={(event) => {
         event.preventDefault();
         const description = this.imageDescriptions.value;
         this.props.tokenURL(description);
        }}
       >
        <div className="form-group mr-sm-2">
         <br></br>
         <input
          id="imageDescriptions"
          type="text"
          ref={(input) => {
           this.imageDescriptions = input;
          }}
          className="form-control"
          placeholder="Enter token ID"
          required
         />
        </div>
        <button type="submit" class="btn btn-primary btn-block btn-lg">
         Get TokenURI
        </button>
       </form>
       <br />
       <br />
       <h2>Grant Mint Access</h2>
       <form
        onSubmit={(event) => {
         event.preventDefault();
         const description = this.mintAddress.value;
         this.props.grantMintAccess(description);
        }}
       >
        <div className="form-group mr-sm-2">
         <br></br>
         <input
          id="mintAddress"
          type="text"
          ref={(input) => {
           this.mintAddress = input;
          }}
          className="form-control"
          placeholder="Wallet address"
          required
         />
        </div>
        <button type="submit" className="btn btn-primary btn-block btn-lg">
         Grand Access
        </button>
       </form>
       <p>&nbsp;</p>
       {this.props.images.map((image, key) => {
        return (
         <div className="card mb-4" key={key}>
          <div className="card-header">
           {/* <img
            className="mr-2"
            width="30"
            height="30"
            src={`data:image/png;base64,${new Identicon(
             image.author,
             30
            ).toString()}`}
           /> */}
           <small className="text-muted">{this.props.nftWords[key]}</small>
          </div>
          <ul id="imageList" className="list-group list-group-flush">
           <li className="list-group-item">
            <p class="text-center">
             <img
              src={`https://ipfs.infura.io/ipfs/${image}`}
              style={{ maxWidth: "420px" }}
             />
            </p>
            <p>{image.description}</p>
           </li>
           <li className="list-group-item">
            <button
             type="submit"
             className="btn btn-success btn-block"
             onClick={(event) => this.props.buyNow(event, key)}
            >
             Buy Now
            </button>
           </li>
          </ul>
         </div>
        );
       })}
      </div>
     </main>
    </div>
   </div>
  );
 }
}

export default Main;
