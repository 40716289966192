import React, { useState, useEffect } from "react";
import { Nav, Container, Navbar, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HomePageIndex.css";
import NftLoot from "../../abis/NftLoot.json";
import Web3 from "web3";

const HomePageIndex = () => {

 const [account, setAccount] = useState("");
 const [nftLoot, setNftLoot] = useState(null);
 const [images, setImages] = useState([]);
 const [loading, setLoading] = useState(true);
 const [nftWords, setNftWords] = useState([]);
 const [data, setData] = useState([]);
 const [totalSupply, setTotalSupply] = useState("");
 const [startFetching, setStartFetching] = useState(false);

 useEffect(() => {
  loadWeb3();
 }, [])


 const loadWeb3 = async () => {
  setStartFetching(true);
  if (window.ethereum) {
   window.web3 = new Web3("https://polygon-mainnet.infura.io/v3/b3eedf1cfbf5489c82fb477496327017");
   // await window.ethereum.enable();
   loadBlockchainData();
   return true;
  } else if (window.web3) {
   window.web3 = new Web3("https://polygon-mainnet.infura.io/v3/b3eedf1cfbf5489c82fb477496327017");
   loadBlockchainData();
   return true;
  } else {
   // window.alert(
   //  "Non-Ethereum browser detected. You should consider trying MetaMask!"
   // );
   window.web3 = new Web3("https://polygon-mainnet.infura.io/v3/b3eedf1cfbf5489c82fb477496327017");
   loadBlockchainData();
   return true;
  }
 }

 const loadBlockchainData = async () => {
  console.log("start- connection...");
  const web3 = window.web3;
  // Load account
  // const accounts = await web3.eth.getAccounts();

  // setAccount(accounts[0]);
  // Network ID
  const networkId = await web3.eth.net.getId();
  console.log("networkd id", networkId);
  const networkData = NftLoot.networks[137];
  if (networkData) {
   console.log("networkdata", networkData);
   const nftLoot = new web3.eth.Contract(NftLoot.abi, networkData.address);
   setNftLoot(nftLoot);
   console.log("nftloot", nftLoot)
   const totalSupply = await nftLoot.methods.totalSupply().call();
   setTotalSupply(totalSupply);
   console.log("total Supply", totalSupply);

   // Load words.

   // Load images
   for (var i = 1; i <= totalSupply; i++) {
    if (i < 7) {
     const singleNft = await nftLoot.methods.nftLootList(i - 1).call();
     const response = await fetch(singleNft);

     const jsonData = await response.json();
     const nftWord = await nftLoot.methods.nftWords(i - 1).call();

     setImages((prevState) => ([...prevState, singleNft]));
     setData((prevState) => ([...prevState, jsonData]));

     setNftWords((prevState) => ([...prevState, nftWord]));
    } else {
     return true;
    }
   }

   setLoading(false);
   setStartFetching(false);
  } else {
   window.alert("instaDapp contract not deployed to detected network.");
  }
 }


 return (
  <>
   <div className="home-page">
    <Navbar expand="lg">
     <Container>
      <Navbar.Brand href="#home">NFT generator</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
       <Nav className="ml-auto">
        <Nav.Link href="#home">FAQ</Nav.Link>
        <Nav.Link href="#link">Resources</Nav.Link>
       </Nav>
      </Navbar.Collapse>
     </Container>
    </Navbar>
    <div className="home-header">
     <h2 className="sec-title text-center">NFT</h2>
     <ul className="sub-title">
      <li>
       <a href="https://opensea.io/collection/codegama-nft" target="_blank">OpenSea</a>
      </li>
      <li>
       <Link>Twitter</Link>
      </li>
      <li>
       <a href="https://polygonscan.com/token/0x49de85c958bc0F53301EE2d4454baE503A497eCA" target={"_blank"}>Contract</a>
      </li>
      <li>
       <Link to="/generate-your-nft">Generate Your NFT..!!</Link>
      </li>
     </ul>
     <p className="sec-para">
      This is randomized adventurer gear generated and stored on chain.
      Stats, images, and other functionality are intentionally omitted for
      others to interpret. Feel free to use Loot in any way you want.
     </p>
    </div>
    <div className="nft-images">
     <Container>
      <h2 className="image-title text-center">Example NFT’s {startFetching != true && loading ? <a onClick={loadWeb3}>Load NFT</a> : ""}</h2>
      {loading ? startFetching ? "Loading..." : "" :
       <>

        <Row>
         {data.length > 0 ? data.map((value) => {
          return (
           <Col md={4}>
            <Card className="image-card">
             <Card.Img src={value.image} className="sec-images" />
             <Card.Body className="image-card-link">
              <Link>{value.name}</Link>
             </Card.Body>
            </Card>
           </Col>
          );
         }) : ""}
        </Row>
        <p className="text-center">For more NFT’s <Link>Click Here.</Link></p>
       </>
      }
     </Container>
    </div>
   </div>
  </>
 );
};

export default HomePageIndex;
