import React, { useEffect, useState } from 'react';
import Avatars, { createAvatar } from '@dicebear/avatars';
import sprites from '@dicebear/avatars-avataaars-sprites';
// import sprites from '@dicebear/avatars-code-sprites';


const TestingIndex = () => {

 let svg;
 const [svgImage, setSvgImage] = useState("");

 useEffect(() => {
  // svg = createAvatar(style, {
  //  seed: 'custom-sdfdsdsfsd',
  //  // ... and other options
  // });


  let options = {
   base64: true
  };
  let avatars = new Avatars(sprites, options);
  let svgs = avatars.create('Hello there... How are you..!');
  setSvgImage(svgs);

  console.log("svg", svgs)
 }, [])

 return (
  <>
   <h1>Testing Index</h1>
   <div>
    {svgImage}
   </div>

   <img src={svgImage} width="100px" height="100px" />
  </>
 );
}

export default TestingIndex;