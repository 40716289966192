import React, { useState, useEffect } from "react";
import { Notify } from "react-redux-notify";

const EmptyLayout = (props) => {
  return (
    <>
      <Notify position="TopRight" />

      {React.cloneElement(props.children)}
    </>
  );
};

export default (EmptyLayout);
