import React, { useEffect, useState } from 'react';
import mergeImages from 'merge-images';
import {Alert , Col , Container , Row} from 'react-bootstrap';
import base64ToImage from "base64-to-image";
import fs from 'fs';
import firstImage from "./Image/1.png";
import secondImage from "./Image/2.png";
import thirdImage from "./Image/3.png";
import fourthImage from "./Image/4.png";
import green from "./BackgroundColor/Color01.png";
import darkgreen from "./BackgroundColor/Color02.png";
import somecolor from "./BackgroundColor/Color03.png";
import purple from "./BackgroundColor/Color04.png";
import yellow from "./BackgroundColor/Color05.png";
import lightgreen from "./BackgroundColor/Color06.png";
import blue from "./BackgroundColor/Color07.png";
import pink from "./BackgroundColor/Color08.png";
import lightyellow from "./BackgroundColor/Color09.png";
import rose from "./BackgroundColor/Color10.png";
import smile from "./MouthDesign/Mouth01.png";
import bigsmile from "./MouthDesign/Mouth02.png";
import laugh from "./MouthDesign/Mouth03.png";
import laughing from "./MouthDesign/Mouth04.png";
import sad from "./MouthDesign/Mouth05.png";
import normalsmile from "./MouthDesign/Mouth06.png";
import saddness from "./MouthDesign/Mouth07.png";
import expression from "./MouthDesign/Mouth08.png";
import happy from "./MouthDesign/Mouth09.png";
import littlesad from "./MouthDesign/Mouth10.png";
import normalEye from "./EyesDesign/Eye01.png";
import laughingEye from "./EyesDesign/Eye02.png";
import sadEye from "./EyesDesign/Eye03.png";
import verySadEye from "./EyesDesign/Eye04.png";
import crying from "./EyesDesign/Eye05.png";
import cry from "./EyesDesign/Eye06.png";
import sweet from "./EyesDesign/Eye07.png";
import loving from "./EyesDesign/Eye08.png";
import heart from "./EyesDesign/Eye09.png";
import sharp from "./EyesDesign/Eye10.png";

/// Keys 

import first from "./Keys/00001.png";
import second from "./Keys/00002.png";
import third from "./Keys/00003.png";

// Keychains

import keyChain1 from "./KeyChain/Keychain-1.png";
import keyChain2 from "./KeyChain/Keychain-2.png";
import keyChain3 from "./KeyChain/Keychain-3.png";
import keyChain4 from "./KeyChain/Keychain-4.png";
import keyChain5 from "./KeyChain/Keychain-5.png";
import keyChain6 from "./KeyChain/Keychain-6.png";
import keyChain7 from "./KeyChain/Keychain-7.png";
import keyChain8 from "./KeyChain/Keychain-8.png";
import keyChain9 from "./KeyChain/Keychain-9.png";
import keyChain10 from "./KeyChain/Keychain-10.png";
import keyChain11 from "./KeyChain/Keychain-11.png";
import keyChain12 from "./KeyChain/Keychain-12.png";
import keyChain13 from "./KeyChain/Keychain-13.png";
import keyChain14 from "./KeyChain/Keychain-14.png";
import keyChain15 from "./KeyChain/Keychain-15.png";

// key background 

import color1 from "./KeyBackground/color-1.png";
import color2 from "./KeyBackground/color-2.png";
import color3 from "./KeyBackground/color-3.png";
import color4 from "./KeyBackground/color-4.png";
import color5 from "./KeyBackground/color-5.png";
import color6 from "./KeyBackground/color-6.png";
import color7 from "./KeyBackground/color-7.png";
import color8 from "./KeyBackground/color-8.png";
import color9 from "./KeyBackground/color-9.png";
import color10 from "./KeyBackground/color-10.png";

import properties from "./properties.json";

const keybackground = [color1, color2, color3, color4, color5, color6, color7, color8, color9, color10];
const keybackgroundColor = properties.background;

const keyArray = [first, second, third];
const keyProperties = properties.key_properties

const keyChainArray = [keyChain1, keyChain2, keyChain3, keyChain4, keyChain5, keyChain6, keyChain7, keyChain8, keyChain9, keyChain10, keyChain11, keyChain12, keyChain13, keyChain14, keyChain15];

const keyChainPropertyName = properties.keyFob;

let mergedImagesArray = [];

const imagesPerCycle = 10 ;

const totalCombinations = keyArray.length * keyChainArray.length;

let generatedImages = 0;

const roundedOffCombinations = Math.floor(totalCombinations / imagesPerCycle) * imagesPerCycle;

let remainingImages = totalCombinations - roundedOffCombinations;

let remainingImagesFlag = false;

let isError = false;



//Declare IPFS
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
}); // leaving out the arguments will default to these values

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");

const ConCatImageIndex = () => {

  const [img, setImg] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [errorMessage , setErrorMessage] = useState(null);

  useEffect(() => {
    // let imageArray = mergeImages([green, smile, normalEye])
    //   .then(async (b64) => {
    //     document.querySelector('img').src = b64;

    //     let imageData = await convertDataURIToBinaryFF(
    //       b64
    //     );
    //     imageData = Buffer(imageData);
    //     console.log("image imageData", imageData);

    //     saveAPI("colorName", "mouthName", "eyeName", b64);
    //     // ipfs.add(imageData, async (error, result) => {
    //     //   console.log("Ipfs result", result);
    //     //   if (error) {
    //     //     console.error(error);
    //     //     return;
    //     //   }
    //     //   setImg(result[0].hash);
    //     // });
    //   })
    imageGenerator();
  }, []);

  let background = 0;

  const mergekeyImage = (key, keychain, keyChainPropertyName , keyProperties) => {
    let imageArray = mergeImages([keybackground[background], key, keychain])
      .then(async (b64) => {
        document.querySelector('img').src = b64;

        let imageData = await convertDataURIToBinaryFF(
          b64
        );
        mergedImagesArray.push({
          key_fob : keyChainPropertyName,
          background : keybackgroundColor[background],
          picture : b64,
          ...keyProperties
        })
        if (background < imagesPerCycle - 1) {
          if(roundedOffCombinations == generatedImages && !remainingImagesFlag){
            remainingImagesFlag = true;
          }
          background++;
          console.log(mergedImagesArray)

        } else {
          background = 0;
          generatedImages = generatedImages + imagesPerCycle;
          const duplicateArray = mergedImagesArray;
          mergedImagesArray = [];
          Promise.all([
            await saveAPI(duplicateArray)
          ]).then();
          
        }
        if(remainingImagesFlag && remainingImages == background && remainingImages < imagesPerCycle){
          console.log("remaining array running")
          background = 0;
          const duplicateArray = mergedImagesArray;
          mergedImagesArray = [];
          Promise.all([
            await saveAPI(duplicateArray)
          ]).then();
          
        }
      })
    // if (background < 9) {
    //   background++;
    // } else {
    //   background = 0;
    // }
    console.log("back", background)
  }

  // keys loop. 

  let keyl = 0;
  let imageCount = 10;

  const keyLoop = () => {
    let chooseKey;
    let chooseProperties;
    setTimeout(() => {
      chooseKey = keyArray[keyl];
      chooseProperties = keyProperties[keyl]
      keychainLoop(chooseKey , chooseProperties);
    }, 1000);
  }

  let keychainl = 0;

  const keychainLoop = (chooseKey , keyProperties) => {
    let chooseKeyChain;
    let chooseKeyChainPropertyName;
    setTimeout(() => {
      chooseKeyChain = keyChainArray[keychainl];
      chooseKeyChainPropertyName = keyChainPropertyName[keychainl];
      // console.log(chooseKey, chooseKeyChain );
      if(!isError){
        mergekeyImage(chooseKey, chooseKeyChain, chooseKeyChainPropertyName  , keyProperties);
      }
      keychainl++;
      imageCount++;
      if (keychainl < keyChainArray.length) {
        keychainLoop(chooseKey , keyProperties);
      } else {
        keyl++;
        keychainl = 0;
        if (keyl < keyArray.length) {
          keyLoop();
        }
      }

    }, 2000);
  }

  let i = 0;

  const imageGenerator = async () => {
    let chooseColor;
    let chooseEye;
    let chooseMouth;
    let chooseMouthPropertyName;
    let chooseEyePropertyName;
    let chooseColorPropertyName;
    console.log("Call");
    keyLoop();
  }


  const saveAPI = async (imagesArray) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ properties : imagesArray})
    };

    await fetch('https://choosecolor.codegama.info/api/user/nft_images_save', requestOptions)
      .then(response => response.json())
      .then(response => {
        if(!response.success){
          isError = true ;
          setErrorMessage( `${ response.error} for key ${response.error_messages}`)
        }
      })
      .then(data => setResponseData(data));
  }


  const convertDataURIToBinaryFF = (dataURI) => {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var raw = window.atob(dataURI.substring(base64Index));
    return Uint8Array.from(
      Array.prototype.map.call(raw, function (x) {
        return x.charCodeAt(0);
      })
    );
  };


  return (
    <>
        <Container>
          <Row>
            <Col lg="8">
              <Alert variant={errorMessage != null ? "danger" : "primary"} className="mt-4 text-center">
                {errorMessage != null ? errorMessage : "Generating images..."}
              </Alert>
            </Col>
          </Row>
        </Container>
      Con cat image
      <img src="" />
    </>
  );
}

export default ConCatImageIndex;