import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../translation/en.json";
import pt from "../translation/pt.json";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import EmptyLayout from "../Layouts/EmptyLayout";
import NotFoundIndex from "../Handlers/ErrorPages/NotFoundIndex";
import TestingIndex from "../Testing/TestingIndex";
import HomePageIndex from "../HomePage/HomePageIndex";
import SeeAllIndex from "../SeeAll/SeeAllIndex";
import GenerateNFTIndex from "../GenerateNft/GenerateNFTIndex";
import ConCatImageIndex from "../ConCatImage/ConCatImageIndex";

const history = createHistory();
const $ = window.$;

setTranslations({ pt, en });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);


class App extends Component {

  componentDidMount() {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }



  render() {
    return (
      <>
        <Switch>
          <AppRoute
            path={"/"}
            component={HomePageIndex}
            exact
            layout={EmptyLayout}
          />
          <AppRoute
            path={"/test"}
            component={TestingIndex}
            layout={EmptyLayout}
          />
          <AppRoute
            path={"/concat"}
            component={ConCatImageIndex}
            layout={EmptyLayout}
          />
          {/* <AppRoute
            path={"/home"}
            component={HomePageIndex}
            layout={EmptyLayout}
          /> */}
          <AppRoute
            path={"/see-all"}
            component={SeeAllIndex}
            layout={EmptyLayout}
          />
          <AppRoute
            path={"/generate-your-nft"}
            component={GenerateNFTIndex}
            layout={EmptyLayout}
          />
          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}

export default App;
