import React from "react";
import { Nav, Container, Navbar, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Yellow from "./images/yellow.svg"
import Codegama from "./images/codegama.svg"
import hello from "./images/hello.svg"
import hi from "./images/hi.svg"
import You from "./images/You.svg"
import pink from "./images/pink.svg"

import "./SeeAllIndex.css";

const SeeAllIndex = () => {
  const listImg = [
    {
      image: Yellow,
      link: "SingularityNET",
      },
        {
      image: Codegama,
      link: "Syscoin",
      },
          {
      image: hello,
      link: "bitFlyer",
      },
             {
      image: hi,
      link: "Dapp",
      },
        {
      image: You,
      link: "Syscoin",
      },
          {
      image: pink,
      link: "SingularityNET",
      },
              {
      image: hi,
      link: "Dapp",
      },
        {
      image: You,
      link: "Syscoin",
      },
         
          
   
  ];
  return (
    <>
      <div className="home-page">
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand href="#home">Loot</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#home">FAQ</Nav.Link>
                <Nav.Link href="#link">Resources</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>      
        <div className="nft-images">
          <Container>
            <h2 className="image-title text-center">Example NFT’s</h2>
            <Row>
              {listImg.map((data) => {
                return (
                  <Col md={3}>
                    <Card className="image-card">
                      <Card.Img src={data.image} className="sec-images" />
                      <Card.Body className="image-card-link">
                                <Link>{data.link}</Link>
                                
                            </Card.Body>
                            
                    </Card>
                  </Col>
                );
              })}
                      </Row>
                     <p className="text-center">For more NFT’s <Link>Click Here.</Link></p>
                     
          </Container>
        </div>
      </div>
    </>
  );
};

export default SeeAllIndex;
